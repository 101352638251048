import CycleDataHeader from './CycleDataHeader';
import CycleDataBody from './CycleDataBody';
import { useGetChargeCycles } from '../queries/useHistory';
import { useSelector } from 'react-redux';
import { State } from '../../../../../types';
import { getAccessToken } from '../../../../../configuration';
import ErrorState from '../../../common/components/states/ErrorState';
import TableLoader from './TableLoader';

interface CycleDataTableProps {
    assetId: string;
    startDate: string;
    endDate: string;
}

const CycleDataTable = ({ assetId, startDate, endDate }: CycleDataTableProps) => {
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const {
        isPending: isLoading,
        isError,
        isSuccess,
        result,
    } = useGetChargeCycles(accessToken, assetId, startDate, endDate);

    if (isError || (isSuccess && result.length === 0)) {
        return <ErrorState stateProps={{ type: 'general', withoutBorder: true }} verticalAlign="80" />;
    }

    return (
        <table className="table table-column-overflow-hidden table-head-filled table-layout-fixed">
            <CycleDataHeader />
            {isLoading ? <TableLoader /> : <CycleDataBody cycles={result} />}
        </table>
    );
};

export default CycleDataTable;
