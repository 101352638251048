import 'whatwg-fetch';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { handleLoginRedirect, history, main, store } from './configuration';

import { config } from './config';
import AppContainer from './features/app/containers/App.container';
import { NoMatch } from './features/app/components/NoMatch';
import { gtmUtil } from './features/app/utils/googleTagManager';
import { ConnectedRouter } from 'connected-react-router';
import { datadogInit } from './features/datadog';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';

datadogInit();

const queryClient = new QueryClient();
const isDev = import.meta.env.DEV;
const isProd = import.meta.env.PROD;

const renderApplication = (): void => {
    createRoot(document.getElementById('root') as HTMLElement).render(
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route path="/error" component={NoMatch} />
                        <Route path="/" component={AppContainer} />
                        <Route component={NoMatch} />
                    </Switch>
                </ConnectedRouter>
            </Provider>
            {/* Set this true if you want the dev tools to default to being open */}
            {!window.Cypress && isDev && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    );
};

if (isDev && config.enableMockServer) {
    import('../mocks/browser').then(({ startWorker }) => startWorker()).then(() => main(renderApplication));
}

gtmUtil.startHandlingPageViews(history);

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else if (isProd || (isDev && !config.enableMockServer)) {
    main(renderApplication);
}

if (config.title != null) {
    document.title = config.title;
}
