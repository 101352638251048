import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { markAsSeenPatch } from '../../../../api/events';
import { AccessToken } from '../../../../configuration';
import { Event } from '../models/Event';
import buildPaginationAfterMarkedEvent from './helpers/buildPaginationAfterMarkedEvent';
import { useIntl } from 'react-intl';
import Notification from '@rio-cloud/rio-uikit/Notification';

const useMutationMarkAsSeenApi = (
    accessToken: AccessToken,
    eventId?: string,
    markAll?: boolean,
    isFromWidget?: boolean,
    severityLevel?: string[],
    lastEventId?: number
): UseMutationResult => {
    const intl = useIntl();
    const queryClient = useQueryClient();

    const queryName = isFromWidget ? ['latest-events'] : ['events-new', severityLevel];

    return useMutation({
        mutationFn: () => markAsSeenPatch(accessToken, eventId, markAll, severityLevel, lastEventId),
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: response => {
            if (isFromWidget) {
                if (!response.ok) {
                    Notification.error(
                        intl.formatMessage({ id: 'e4c.overview.events.widget.modal.markAllAsSeen.error' }),
                        '',
                        3000
                    );
                    return;
                }
                Notification.success(
                    intl.formatMessage({ id: 'e4c.overview.events.widget.modal.markAllAsSeen.success' }),
                    '',
                    3000
                );
            }

            // ✅ update detail view directly
            if (isFromWidget || markAll || !eventId) {
                queryClient.setQueryData<Event[]>(queryName, (): any => ({
                    pages: [],
                    pageParams: [''],
                }));
            } else {
                // Timeout due to mark as seen animation
                setTimeout(() => {
                    queryClient.setQueryData<Event[]>(queryName, (data: any): any =>
                        buildPaginationAfterMarkedEvent(data, eventId)
                    );
                }, 150);
            }
        },
    });
};

export default useMutationMarkAsSeenApi;
