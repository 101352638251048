import { SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import HorizontalCard from '../../../../../../common/components/HorizontalCard';
import DeleteButton from './DeleteButton';
import LanguageSelector, { SelectOptions } from './LanguageSelector';
import ContactInput from './ContactInput';
import { ContactInfoSection, ErrorType } from './ContactSection';
import HorizontalButtons, { HorizontalButton } from '../../../../../../common/components/HorizontalButtons';

export interface ContactFormProps {
    contacts: ContactInfoSection[];
    handleError: Function;
    handleChange: Function;
    handleAddContact: (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
    handleDelete: Function;
    onSubmit: Function;
    onDiscard: Function;
    showButtons: boolean;
}

const ContactForm = ({
    contacts,
    handleError,
    handleChange,
    handleAddContact,
    handleDelete,
    onSubmit,
    onDiscard,
    showButtons,
}: ContactFormProps) => {
    const intl = useIntl();

    const buttons: HorizontalButton[] = [
        {
            className: 'btn-default',
            text: intl.formatMessage({ id: 'e4c.global.discardChanges' }),
            onClick: () => onDiscard(),
        },
        {
            className: 'btn-primary',
            text: intl.formatMessage({ id: 'e4c.global.saveChanges' }),
            onClick: () => onSubmit(),
        },
    ];

    const getContactError = (value: ContactInfoSection): ErrorType | undefined => {
        if (value.contactError) {
            return 'contactError';
        }
        if (value.duplicateContactError) {
            return 'duplicateContactError';
        }
        return undefined;
    };

    const renderContactCard = (contact: ContactInfoSection, index: number) => {
        const { contact: contactValue, language, languageError, type } = contact;
        const showAddContactButton = index === contacts.length - 1 && contacts.length < 5;

        return (
            <HorizontalCard
                testId={`contact-form-${type.toLowerCase()}`}
                key={index}
                className="bg-lightest margin-bottom-10"
            >
                <div className="display-flex margin-bottom-5">
                    <div style={{ flexBasis: '50%' }}>
                        <ContactInput
                            contact={contactValue}
                            handleChange={(phone: string) => handleChange('contact', index, phone)}
                            error={getContactError(contact)}
                            setFormatError={(errorValue: boolean) => handleError('contactError', index, errorValue)}
                        />
                    </div>
                    <div style={{ flexBasis: '50%' }}>
                        <LanguageSelector
                            languageSelected={language}
                            handleChange={(languageSelected: SelectOptions) =>
                                handleChange('language', index, languageSelected.id)
                            }
                            error={!!languageError}
                            setError={(errorValue: boolean) => handleError('languageError', index, errorValue)}
                        />
                    </div>
                    <DeleteButton handleClick={() => handleDelete(index)} />
                </div>
                <span className="text-color-dark text-size-12">
                    {intl.formatMessage({
                        id: 'e4c.overview.events.widget.settings.critical.sms.consent',
                    })}
                </span>
                {showAddContactButton && (
                    <div className="float-right" data-testid="add-another-contact-button">
                        <button className="btn btn-link btn-primary padding-0" onClick={handleAddContact}>
                            {intl.formatMessage({
                                id: 'e4c.overview.events.widget.settings.critical.sms.addAnother',
                            })}
                        </button>
                    </div>
                )}
            </HorizontalCard>
        );
    };

    return (
        <div className="padding-bottom-25">
            {contacts?.length ? (
                contacts.map((value: ContactInfoSection, index: number) => renderContactCard(value, index))
            ) : (
                <div data-testid="add-new-contact-button">
                    <Button onClick={handleAddContact}>
                        <span className="rioglyph rioglyph-plus-light"></span>
                        <span>
                            {intl.formatMessage({ id: 'e4c.overview.events.widget.settings.critical.sms.addPhone' })}
                        </span>
                    </Button>
                </div>
            )}
            {showButtons && (
                <div className="display-flex flex-column align-items-end margin-top-50">
                    <HorizontalButtons buttons={buttons} />
                </div>
            )}
        </div>
    );
};

export default ContactForm;
