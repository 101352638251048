import { ReactElement, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import SimpleDialog from '@rio-cloud/rio-uikit/SimpleDialog';
import { SeverityEventLevel } from '../../../../models/SeverityEventLevelEnum';
import { useGetEventSettings, usePatchEventSettings } from '../../../../queries/useEventSettings';
import { ConfigItemProps } from './config/ConfigItem';
import ConfigSection from './config/ConfigSection';
import { ContactDetail, EventsSettingsUI, SettingsDetails } from './Models/IModel';
import { State } from '../../../../../../../types';
import { getAccessToken } from '../../../../../../../configuration';
import ContactSection from './contacts/ContactSection';
import SaveChanges from '../confirmationPanels/SaveChanges';
import cloneDeep from 'lodash/fp/cloneDeep';
import { NavigationActions, NavigationOperation } from './EventSettingsModal';
import PendingChanges from '../confirmationPanels/PendingChanges';
import PageLoader from '../../../../../common/components/PageLoader';
import InformativeState from '../../../../../common/components/states/InformativeState';
import ErrorState from '../../../../../common/components/states/ErrorState';
import { EVENT_SETTINGS_PATH } from '../../../../../common/constants/paths/paths';
import { useHistory } from 'react-router';

const mapEventConfig = (settings: SettingsDetails[], handleChange: Function): ConfigItemProps[] =>
    settings?.map(
        (item: SettingsDetails): ConfigItemProps => ({
            eventName: item.name,
            eventDescription: item.description,
            eventEnabled: item.enabled,
            updatable: item.updatable,
            onChangeFunction: () => handleChange(item.type),
            eventMessage: item.extraInfo,
        })
    );

interface StatusContentProps {
    children?: ReactElement;
}

const StatusContent = ({ children }: StatusContentProps) => {
    const intl = useIntl();
    const Status = (
        <div className="display-flex flex-column justify-content-center align-items-center margin-y-50">{children}</div>
    );
    return (
        <SimpleDialog
            show
            title={intl.formatMessage({
                id: 'e4c.global.saveChanges',
            })}
            content={Status}
            showCloseButton={false}
        />
    );
};

const EventSeveritySettings = ({
    severity,
    handleUndoDiscardChanges,
    discardOperation,
    navigationActions,
}: {
    severity: string;
    handleUndoDiscardChanges: Function;
    discardOperation: NavigationOperation;
    navigationActions: NavigationActions;
}): ReactElement => {
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { isPending: isLoadingGet, isError: isErrorGet, eventSettingsData } = useGetEventSettings(accessToken);
    const intl = useIntl();
    const history = useHistory();
    const path = history.location.pathname;
    const levelSeverity = severity ? severity : path.replace(`${EVENT_SETTINGS_PATH}/`, '');

    const eventSettingResponse = eventSettingsData
        ?.mapSettingsWithSeverity()
        .find(item => item.eventSeverityLevel === levelSeverity) as EventsSettingsUI;
    const contactInfo = cloneDeep(eventSettingResponse?.eventSettings?.contactsInfo) as ContactDetail[];

    const [settings, setSettings] = useState<SettingsDetails[]>([]);
    const [contacts, setContacts] = useState<ContactDetail[]>([]);
    const [changedContacts, setChangedContacts] = useState<ContactDetail[] | null>(null);
    const [showSavePanel, setShowSavePanel] = useState(false);
    const [showPendingPanel, setShowPendingPanel] = useState(false);
    const [pendingChanges, setPendingChanges] = useState(false);
    const {
        isError,
        isPending: isLoading,
        isSuccess,
        mutate,
        reset,
    } = usePatchEventSettings(accessToken, levelSeverity, settings, changedContacts);

    const performNavigationAction = useCallback(() => {
        if (discardOperation && !pendingChanges) {
            navigationActions[discardOperation]();
            handleUndoDiscardChanges();
        }
    }, [discardOperation, pendingChanges, navigationActions, handleUndoDiscardChanges]);

    useEffect(() => {
        if (eventSettingResponse && settings.length === 0 && contacts.length === 0) {
            setSettings(eventSettingResponse?.eventSettings?.settings);
            setContacts(cloneDeep(eventSettingResponse?.eventSettings?.contactsInfo));
        }
    }, [eventSettingResponse, settings, contacts]);

    useEffect(() => {
        setShowPendingPanel(Boolean(discardOperation));
        performNavigationAction();
    }, [discardOperation, performNavigationAction]);

    const hidePanels = () => {
        setShowPendingPanel(false);
        setShowSavePanel(false);
    };

    const discardChanges = () => {
        setChangedContacts(null);
        setPendingChanges(false);
    };

    const handleSettingsChange = (type: string) => {
        const data = settings.map(item => {
            if (item.type === type) {
                item.enabled = !item.enabled;
            }
            return item;
        });
        setSettings(data);
        mutate();
        reset();
    };

    const handleSaveContact = (newContacts: ContactDetail[]) => {
        setShowSavePanel(true);
        setChangedContacts(newContacts);
    };

    const handleDiscardContact = (newContacts: ContactDetail[]) => {
        setShowPendingPanel(true);
        setChangedContacts(newContacts);
    };

    const handleCancel = () => {
        hidePanels();
        if (changedContacts) {
            setContacts(changedContacts);
        }
        setChangedContacts(null);
    };

    const handlePendingCancel = (callback?: Function) => {
        handleCancel();
        handleUndoDiscardChanges();
        if (callback) {
            callback();
        }
    };

    const handlePendingDiscard = () => {
        setShowPendingPanel(false);
        discardChanges();
        const modalNavigationChosen = discardOperation === 'close' || discardOperation === 'goBack';
        if (modalNavigationChosen) {
            navigationActions[discardOperation]();
            handleUndoDiscardChanges();
        } else {
            setContacts(contactInfo);
        }
    };

    const handleCloseSavePanel = () => {
        setShowSavePanel(false);
        navigationActions.goBack();
    };

    if ((isError || isErrorGet) && !showSavePanel) {
        return <ErrorState stateProps={{ type: 'general' }} />;
    }

    if (isLoadingGet && !showSavePanel) {
        return <PageLoader />;
    }

    if (showSavePanel) {
        const loadingContent = isLoading ? (
            <StatusContent>
                <PageLoader />
            </StatusContent>
        ) : null;
        const successContent = isSuccess ? (
            <StatusContent>
                <InformativeState
                    type="success"
                    headline={intl.formatMessage({ id: 'e4c.overview.events.widget.settings.informationChanged' })}
                    message={intl.formatMessage({
                        id: 'e4c.overview.events.widget.settings.informationChangedDescription',
                    })}
                    buttons={[
                        {
                            text: intl.formatMessage({
                                id: 'e4c.overview.events.widget.settings.goToEventSettings',
                            }),
                            onClick: handleCloseSavePanel,
                            className: 'btn-primary',
                        },
                    ]}
                >
                    <div className="text-size-12 text-normal text-color-gray margin-top-25">
                        *
                        {intl.formatMessage({
                            id: 'e4c.overview.events.widget.settings.informationChangedDescriptionFootnote',
                        })}
                    </div>
                </InformativeState>
            </StatusContent>
        ) : null;
        const errorContent = isError ? (
            <StatusContent>
                <ErrorState
                    stateProps={{
                        type: 'general',
                        buttons: [
                            {
                                text: intl.formatMessage({
                                    id: 'e4c.global.cancel',
                                }),
                                onClick: () => handlePendingCancel(reset),
                                className: 'btn-default',
                            },
                            {
                                text: intl.formatMessage({
                                    id: 'e4c.global.errorState.btnTryAgain',
                                }),
                                onClick: () => mutate(),
                                className: 'btn-primary',
                            },
                        ],
                    }}
                />
            </StatusContent>
        ) : null;
        return (
            <SaveChanges
                handleCancel={handleCancel}
                handleSave={mutate}
                status={loadingContent || successContent || errorContent}
            />
        );
    }
    if (showPendingPanel && pendingChanges) {
        return <PendingChanges handleCancel={() => handlePendingCancel()} handleDiscard={handlePendingDiscard} />;
    }

    if (!eventSettingResponse) {
        return (
            <ErrorState
                stateProps={{
                    type: 'notfound',
                    message: <FormattedMessage id="e4c.global.notFoundState.message" />,
                }}
            />
        );
    }

    return (
        <>
            <ConfigSection configItems={mapEventConfig(settings, handleSettingsChange)} />
            {severity === SeverityEventLevel.CRITICAL && (
                <ContactSection
                    initialContacts={contactInfo}
                    changedContacts={contacts}
                    handleSaveContact={handleSaveContact}
                    handleDiscardContact={handleDiscardContact}
                    handlePendingChanges={(value: boolean) => setPendingChanges(value)}
                />
            )}
        </>
    );
};

export default EventSeveritySettings;
