import { useQuery } from '@tanstack/react-query';
import ServicesApi from '../../../../api/Api';
import { LatestEventsApiResponse } from '../../vehicleCharging/models/api/latestEventsResponse';
import mappingLatestEventsFromServer from '../helpers/mappingLatestEventsFromServer';
import { Events } from '../models/Event';

const useLatestEventsAPI = (
    accessToken?: string | null
): { isPending: boolean; isError: boolean; events: Events[] | undefined; refetch: Function } => {
    let events: Events[] | undefined;
    const { isPending, isError, isSuccess, data, refetch } = useQuery<unknown, Error, LatestEventsApiResponse>({
        queryKey: ['latest-events'],
        queryFn: () => ServicesApi.getLatestEvents(accessToken).then(res => res.json()),
        retry: 3,
        refetchOnWindowFocus: false, // change if we want short polling, this will only refetch on mount
    });

    if (isSuccess) {
        events = mappingLatestEventsFromServer(data);
    }

    return { isPending, isError, events, refetch };
};

export default useLatestEventsAPI;
