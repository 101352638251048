import { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import classNames from 'classnames';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { PLACEMENT } from '@rio-cloud/rio-uikit/values/Placement';
import TooltipRef from '../../../../../common/components/Tooltip/Tooltip';
import { State } from '../../../../../../../types';
import { getAccessToken } from '../../../../../../../configuration';
import { ASSET_NOT_FOUND_ERROR, useGetVehicleChargingConfiguration } from '../../../../queries/useVehicleChargingAPI';
import { VehicleDetailsContext } from '../../../../../../../providers/contexts';
import { ChargingModeEnum } from '../../../../types';
import ErrorState from '../../../../../common/components/states/ErrorState';
import { ChargingTab, createChargingTabs } from './createChargingTabs';
import VehiclesDetailsError from '../../VehicleDetailsError/VehicleDetailsError';
import { ErrorCodeEnum } from '../../../../../common/enums/ErrorCode';
import { isReadOnlyUser } from './utils';

const ChargingMode = ({ assetId }: { assetId: string }) => {
    const intl = useIntl();
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const {
        isPending: isLoading,
        isError,
        error,
        vehicleChargingConfiguration,
    } = useGetVehicleChargingConfiguration(accessToken, assetId);
    const {
        activeTab,
        setActiveTab,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        setChangedChargingTab,
        hideTimerForm,
        setTimers,
        timers,
        setUnsavedTimers,
        setImmediateConfiguration,
        resetContext,
        dialog,
        isReadOnly,
        setIsReadOnly,
    } = useContext(VehicleDetailsContext);

    const [chargingTabs, setChargingTabs] = useState<ChargingTab[]>([]);

    // Ref to track if tabs were initialized
    const tabsInitializedRef = useRef(false);
    const initialTabRef = useRef<string>('');

    // Reset tab initialization and the context if assetId changes
    useEffect(() => {
        tabsInitializedRef.current = false;
        resetContext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetId]);

    useEffect(() => {
        if (vehicleChargingConfiguration) {
            setImmediateConfiguration(cloneDeep(vehicleChargingConfiguration.immediateConfiguration));
            setTimers(cloneDeep(vehicleChargingConfiguration.timers || []));
            setUnsavedTimers(cloneDeep(vehicleChargingConfiguration.timers || []));
            setIsReadOnly(isReadOnlyUser(vehicleChargingConfiguration));
        }
    }, [setImmediateConfiguration, setIsReadOnly, setTimers, setUnsavedTimers, vehicleChargingConfiguration]);

    // Handle vehicleDetailsData changes to set up tabs and activeTab
    useEffect(() => {
        if (vehicleChargingConfiguration && !tabsInitializedRef.current) {
            const tabs = createChargingTabs(assetId);
            setChargingTabs(tabs);

            const chargingMode = vehicleChargingConfiguration.chargingMode;
            const initialTab = chargingMode === ChargingModeEnum.TIMER ? tabs[1]?.id : tabs[0]?.id;
            setActiveTab(initialTab || '');
            initialTabRef.current = initialTab || ''; // Save the initial tab in the ref
            setChangedChargingTab(false);
            tabsInitializedRef.current = true; // Set ref to true to prevent repeated initialization
        }
    }, [vehicleChargingConfiguration, assetId, setChangedChargingTab]);

    const handleSwitchTabs = (tabId: string) => {
        if (hasUnsavedChanges) {
            dialog.openDialog(() => handleDialogConfirm(tabId));
        } else {
            switchToTab(tabId);
        }
    };

    const handleDialogConfirm = (tabId: string) => {
        if (tabId) {
            switchToTab(tabId);
            setUnsavedTimers(cloneDeep(timers));
            setHasUnsavedChanges(false);
        }
    };

    const switchToTab = (tabId: string) => {
        setActiveTab(tabId);
        setChangedChargingTab(tabId !== initialTabRef.current);
        hideTimerForm();
    };

    if (isLoading) {
        return <Spinner text={intl.formatMessage({ id: 'e4c.spinner.loading' })} />;
    }
    if ((error as Error)?.message?.includes(ASSET_NOT_FOUND_ERROR)) {
        return <VehiclesDetailsError errorCode={ErrorCodeEnum.NOT_FOUND} />;
    }
    if (isError || !vehicleChargingConfiguration) {
        return <ErrorState stateProps={{ type: 'general', withoutBorder: true }} />;
    }

    return (
        <div className="margin-20" data-testid="charging-mode">
            <div className="margin-bottom-20">
                <span className="padding-right-5 text-bold">
                    <FormattedMessage id="e4c.vehicle.details.chargingMode.title" />
                </span>
                <TooltipRef
                    tooltipContent={intl.formatMessage({ id: 'e4c.vehicle.details.chargingMode.tooltip' })}
                    placement={PLACEMENT.BOTTOM_START}
                    width={400}
                    textAlignment="left"
                    ellipsedChildren={false}
                >
                    <span className="rioglyph rioglyph-exclamation-sign" />
                </TooltipRef>
            </div>
            <div>
                <ul className="nav nav-tabs nav-justified nav-justified-word-wrap">
                    {chargingTabs.map(tab => (
                        <li
                            key={tab.id}
                            className={classNames({
                                active: activeTab === tab.id,
                                disabled: isReadOnly && activeTab !== tab.id,
                            })}
                        >
                            <a onClick={() => handleSwitchTabs(tab.id)} data-testid={tab.id}>
                                {tab.label}
                            </a>
                        </li>
                    ))}
                </ul>
                {chargingTabs.map(
                    tab =>
                        activeTab === tab.id && (
                            <div key={tab.id} id={tab.id} className="tab-content-bordered padding-25">
                                {tab.content}
                            </div>
                        )
                )}
            </div>
        </div>
    );
};

export default ChargingMode;
