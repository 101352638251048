import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import Menu from './Menu';
import { SeverityEventLevelMVP } from '../../../../../models/SeverityEventLevelEnum';
import { useGetEventSettings } from '../../../../../queries/useEventSettings';
import { useSelector } from 'react-redux';
import { State } from '../../../../../../../../types';
import { getAccessToken } from '../../../../../../../../configuration';
import ErrorState from '../../../../../../common/components/states/ErrorState';

const LoadingMenu = () => (
    <div className="display-flex flex-column" data-testid="loading-menu-settings">
        {Object.keys(SeverityEventLevelMVP).map(level => (
            <ContentLoader key={level} className="height-60 margin-bottom-10" />
        ))}
    </div>
);

const EventSettingsMenu = (): JSX.Element => {
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { isPending: isLoading, isError, eventSettingsData } = useGetEventSettings(accessToken);

    if (isLoading) {
        return <LoadingMenu />;
    }

    if (isError) {
        return <ErrorState stateProps={{ type: 'general', withoutBorder: true }} />;
    }

    if (eventSettingsData) {
        const args = { events: eventSettingsData.mapSettingsWithSeverity() };
        return <Menu {...args} />;
    }

    return <LoadingMenu />;
};

export default EventSettingsMenu;
