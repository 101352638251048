import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { deleteEvents } from '../../../../api/events';
import { AccessToken } from '../../../../configuration';

const useMutationDeleteEventApi = (
    accessToken: AccessToken,
    eventId?: string,
    deleteAll?: boolean,
    severityLevel?: string[]
): UseMutationResult => {
    return useMutation({ mutationFn: () => deleteEvents(accessToken, eventId, deleteAll, severityLevel) });
};

export default useMutationDeleteEventApi;
