import { useQuery } from '@tanstack/react-query';
import { IntlShape, useIntl } from 'react-intl';
import _, { isEmpty } from 'lodash';
import { TreeItem } from '@rio-cloud/rio-uikit';
import { TreeGroup } from '@rio-cloud/rio-uikit/Tree';
import { fetchAssets, fetchTags } from '../../../../api/assetTree';
import { AccessToken } from '../../../../configuration';
import { AssetTagsResponse, AssetWithTagsResponse } from '../components/AssetTreeSidebar/types';

const UNGROUPED_ITEMS_GROUP_ID = 'unassigned';

export const useGetAssets = (accessToken: AccessToken) => {
    const { isPending, isError, isSuccess, data } = useQuery({
        queryKey: ['get-assets'],
        queryFn: () => fetchAssets(accessToken).then(res => res.json()),
        staleTime: Infinity,
        gcTime: 1000 * 60 * 60 * 24, // Data will never go stale and Cache time for 24 hours
    });

    const assets = data ? mappingAssetsFromServer(data.items) : [];

    return { isPending, isError, isSuccess, assets };
};

const mappingAssetsFromServer = (data: AssetWithTagsResponse[]): TreeItem[] => {
    return data.map((assetWithTag: AssetWithTagsResponse) => {
        const groupIds = _.get(assetWithTag, '_embedded.tags.items', []);
        return {
            id: assetWithTag.id,
            name: assetWithTag.name,
            type: assetWithTag.type,
            subType: 'fuel-electric',
            groupIds: isEmpty(groupIds) ? [UNGROUPED_ITEMS_GROUP_ID] : groupIds.map((item: { id: string }) => item.id),
        };
    });
};

export const useGetGroups = (accessToken: AccessToken) => {
    const intl = useIntl();
    const { isPending, isError, isSuccess, data } = useQuery({
        queryKey: ['get-groups'],
        queryFn: () => fetchTags(accessToken).then(res => res.json()),
        staleTime: Infinity,
        gcTime: 1000 * 60 * 60 * 24, // Data will never go stale and Cache time for 24 hours
    });

    const groups = data ? mappingGroupsFromServer(data.items, intl) : [];

    return { isPending, isError, isSuccess, groups };
};

const getUngroupedGroup = (intl: IntlShape) => ({
    id: UNGROUPED_ITEMS_GROUP_ID,
    name: intl.formatMessage({ id: 'e4c.assetTree.groups.ungrouped' }),
    position: 'last',
});

const mappingGroupsFromServer = (data: AssetTagsResponse[], intl: IntlShape): TreeGroup[] => {
    const groups = !data || isEmpty(data) ? [] : [...data, getUngroupedGroup(intl)];

    return groups.map((group: any) => {
        return {
            id: group.id,
            name: group.name,
            position: group.position,
        };
    });
};
