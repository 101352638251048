import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Tag from '@rio-cloud/rio-uikit/Tag';
import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AccessToken, getAccessToken } from '../../../../../../configuration';
import { State } from '../../../../../../types';
import {
    DELETE_EVENT_PATH,
    EVENT_SETTINGS_PATH,
    EVENTS_PATH,
    NEW_EVENTS_PATH,
    SEEN_EVENTS_PATH,
} from '../../../../common/constants/paths/paths';
import { Event } from '../../../models/Event';
import { EventStatus } from '../../../models/EventStatusEnum';
import { SeverityEventLevelMVP } from '../../../models/SeverityEventLevelEnum';
import useEventsInfinitePaginationApi from '../../../queries/useEventsInfinitePaginationApi';
import DeleteEvents from './DeleteEvents';
import MarkAsSeen from './MarkAsSeen';
import ConfirmDeletion from './ConfirmDeletion';
import EmptyEvents from './EmptyEvents';
import EventCardModal from './eventCard/EventCardModal';
import EventSettings from './EventSettings';
import ErrorState from '../../../../common/components/states/ErrorState';
import { OVERVIEW_PATH } from '../../../../../../configuration/setup/path';
import { LoadMoreButton } from '../../../../common/components/LoadMore/LoadMoreButton';

const getProps = (severityLevel: SeverityEventLevelMVP): { icon: string; key: string } => {
    switch (severityLevel) {
        case SeverityEventLevelMVP.CRITICAL:
            return { icon: 'rioglyph-error-sign', key: 'e4c.overview.events.widget.modal.severity.critical' };
        case SeverityEventLevelMVP.WARNING:
            return { icon: 'rioglyph-exclamation-sign', key: 'e4c.overview.events.widget.modal.severity.warning' };
        case SeverityEventLevelMVP.SUCCESS:
            return { icon: 'rioglyph-ok-sign', key: 'e4c.overview.events.widget.modal.severity.success' };
    }
};

const EventList: FC<{
    events: Event[];
    isLoading: boolean;
    isError: boolean;
    fetchNextPage: Function;
    hasNextPage: boolean;
    status: EventStatus;
    accessToken: AccessToken;
    severityLevel: string[];
}> = ({ events, isLoading, isError, fetchNextPage, hasNextPage, status, accessToken, severityLevel }) => {
    if (isLoading) {
        return (
            <div className="display-flex flex-column flex-1-1">
                <ContentLoader className="height-60" />
            </div>
        );
    }

    if (isError) {
        return <ErrorState stateProps={{ type: 'general', withoutBorder: true }} />;
    }

    if (!events?.length) {
        return <EmptyEvents status={status} />;
    }

    return (
        <>
            <div className="display-flex flex-column flex-1-1" style={{ gap: '16px', paddingBottom: '16px' }}>
                {events?.map((event: Event) => (
                    <EventCardModal
                        key={event.id}
                        event={event}
                        status={status}
                        accessToken={accessToken}
                        severityLevel={severityLevel}
                    />
                ))}
            </div>
            <LoadMoreButton
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                buttonStyle="margin-bottom-15"
                shouldRender={hasNextPage}
            />
        </>
    );
};

const EventsContentModal = () => {
    const intl = useIntl();
    const history = useHistory();
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const [severityLevel, setSeverityLevel] = useState<string[]>([]);

    const {
        isPending: isLoadingNew,
        isError: isErrorNew,
        refetch: refetchNewEvents,
        fetchNextPage: fetchNewEventsPage,
        events: newEvents,
        hasNextPage: hasNextNewPage,
        countNumber: countNumberNew,
        lastEventId: lastEventId,
    } = useEventsInfinitePaginationApi(accessToken, EventStatus.NEW.toLowerCase(), severityLevel);

    const {
        isPending: isLoadingSeen,
        isError: isErrorSeen,
        refetch: refetchSeenEvents,
        fetchNextPage: fetchSeenEventsPage,
        events: seenEvents,
        hasNextPage: hasNextSeenPage,
        countNumber: countNumberSeen,
    } = useEventsInfinitePaginationApi(accessToken, EventStatus.SEEN.toLowerCase(), severityLevel);

    const refetchEvents = useCallback(() => {
        refetchNewEvents();
        refetchSeenEvents();
    }, [refetchNewEvents, refetchSeenEvents]);

    useEffect(() => {
        refetchEvents();
    }, [countNumberNew, refetchEvents]);

    const isNewStatus = history.location.pathname.includes(NEW_EVENTS_PATH);

    const getColorSeverityActive = (level: string) => {
        return !severityLevel.includes(level) ? 'text-color-dark border-color-gray' : 'active';
    };

    const handleClickSeen = () => {
        if (isNewStatus) {
            history.push(SEEN_EVENTS_PATH);
            refetchSeenEvents();
        }
    };

    const handleClickNew = () => {
        if (!isNewStatus) {
            history.push(NEW_EVENTS_PATH);
            refetchNewEvents();
        }
    };

    const handleClickSeverity = (level: string) => {
        if (severityLevel.includes(level)) {
            setSeverityLevel(severityLevel.filter(lvl => lvl !== level));
        } else {
            setSeverityLevel([...severityLevel, level]); // dont change destructure, if you push it doesnt change state
        }
    };

    if (history.location.pathname.includes(DELETE_EVENT_PATH)) {
        return (
            <ConfirmDeletion accessToken={accessToken} severityLevel={severityLevel} refetchEvents={refetchEvents} />
        );
    }

    return (
        <div>
            <ul className="nav nav-pills nav-pills-filled nav-justified" style={{ marginBottom: '16px' }}>
                <li className={isNewStatus ? 'active' : ''}>
                    <span className="text-size-14 text-medium" onClick={() => handleClickNew()}>
                        {intl.formatMessage({ id: 'e4c.overview.events.widget.modal.new' })} ({countNumberNew ?? '0'})
                    </span>
                </li>
                <li className={!isNewStatus ? 'active' : ''}>
                    <span
                        className="text-size-14 text-medium"
                        data-testid="seen-button"
                        onClick={() => handleClickSeen()}
                    >
                        {intl.formatMessage({ id: 'e4c.overview.events.widget.modal.seen' })} ({countNumberSeen ?? '0'})
                    </span>
                </li>
            </ul>
            <div className="display-flex gap-4">
                {Object.values(SeverityEventLevelMVP).map(level => {
                    const { icon, key } = getProps(level);
                    return (
                        <Tag
                            key={level}
                            icon={icon}
                            size="small"
                            className={getColorSeverityActive(level)}
                            onClick={() => handleClickSeverity(level)}
                        >
                            {intl.formatMessage({ id: key })}
                        </Tag>
                    );
                })}
            </div>
            <div className="display-flex justify-content-end" style={{ marginTop: '16px' }}>
                {isNewStatus ? (
                    <>
                        <MarkAsSeen
                            accessToken={accessToken}
                            markAll
                            severityLevel={severityLevel}
                            lastEventId={lastEventId}
                            disabled={!newEvents?.length}
                        />
                        <div className="margin-left-15">
                            <EventSettings />
                        </div>
                    </>
                ) : (
                    <DeleteEvents deleteAll disabled={!seenEvents?.length} />
                )}
            </div>
            {isNewStatus ? (
                <div className="margin-top-20 height-600">
                    <EventList
                        key="new-events"
                        events={newEvents}
                        isLoading={isLoadingNew}
                        isError={isErrorNew}
                        fetchNextPage={fetchNewEventsPage}
                        hasNextPage={hasNextNewPage}
                        status={EventStatus.NEW}
                        accessToken={accessToken}
                        severityLevel={severityLevel}
                    />
                </div>
            ) : (
                <div className="margin-top-20 height-600">
                    <EventList
                        key="seen-events"
                        events={seenEvents}
                        isLoading={isLoadingSeen}
                        isError={isErrorSeen}
                        fetchNextPage={fetchSeenEventsPage}
                        hasNextPage={hasNextSeenPage}
                        status={EventStatus.SEEN}
                        accessToken={accessToken}
                        severityLevel={severityLevel}
                    />
                </div>
            )}
        </div>
    );
};

const EventsModal: FC = () => {
    const history = useHistory();
    const intl = useIntl();
    const showOnlyEventsModal =
        history.location.pathname.includes(EVENTS_PATH) && !history.location.pathname.includes(EVENT_SETTINGS_PATH);
    return (
        <Dialog
            show={showOnlyEventsModal}
            title={intl.formatMessage({ id: 'e4c.overview.events.widget.modal.title' })}
            body={<EventsContentModal />}
            onHide={() => history.push(OVERVIEW_PATH)}
            showCloseButton
            useOverflow
        />
    );
};

export default EventsModal;
