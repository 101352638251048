import { useIntl } from 'react-intl';
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import { commonPhoneNumberValidation } from '../../../../../../../validation/userInputValidation';
import { ErrorType } from './ContactSection';

export interface ContactInputProps {
    contact?: string;
    handleChange: Function;
    setFormatError: Function;
    error?: ErrorType;
}

const ContactInput = ({ contact, handleChange, error, setFormatError }: ContactInputProps): JSX.Element => {
    const intl = useIntl();

    const informationLabel = intl.formatMessage({ id: 'e4c.overview.events.widget.settings.critical.sms.phoneNumber' });

    const errorMessageId = `e4c.overview.events.widget.settings.critical.sms.validation.${error === 'contactError' ? 'format' : 'duplicate'}`;

    const handleContactChange = (inputValue: string) => handleChange(inputValue);

    const handleClear = () => setFormatError(false);

    const contactValidation = () => {
        const isPhoneNumberValid = commonPhoneNumberValidation(contact);
        setFormatError(!isPhoneNumberValid);
    };

    return (
        <div
            className={`flex-1-1 padding-right-15 form-group ${error ? 'has-error has-feedback' : ''}`}
            data-testid="contact-number-input"
        >
            <div>
                <label>{informationLabel}</label>
                <span className="text-color-dark">*</span>
            </div>
            <ClearableInput
                value={contact}
                onChange={handleContactChange}
                onBlur={contactValidation}
                onClear={handleClear}
                placeholder={informationLabel}
            />
            {error && (
                <span data-testid="contact-input-error" className="help-block">
                    <span>{intl.formatMessage({ id: errorMessageId })}</span>
                </span>
            )}
        </div>
    );
};

export default ContactInput;
