import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchEventsSettings } from '../../../../api/events';
import { AccessToken } from '../../../../configuration';
import EventSettingsResponse from '../overviewWidget/contentWidget/events/settings/Models/EventSettingsResponse';
import { requestEventSetting } from '../overviewWidget/contentWidget/events/settings/Models/EventSettingsRequest';
import { ContactDetail, SettingsDetails } from '../overviewWidget/contentWidget/events/settings/Models/IModel';

export const useGetEventSettings = (accessToken: AccessToken) => {
    const { isPending, isError, data } = useQuery({
        queryKey: ['events-settings'],
        queryFn: () => fetchEventsSettings({ accessToken }).then(res => res.json()),
    });

    const eventSettingsData = data ? new EventSettingsResponse(data) : null;

    return { isPending, isError, eventSettingsData };
};

export const usePatchEventSettings = (
    accessToken: AccessToken,
    severity: string,
    settings: SettingsDetails[],
    contactsInfo: ContactDetail[] | null
) => {
    const body = requestEventSetting(severity, settings, contactsInfo);
    return useMutation({
        mutationFn: () =>
            fetchEventsSettings({
                accessToken,
                body: JSON.stringify(body),
                contentType: 'application/merge-patch+json',
                method: 'PATCH',
            }),
    });
};
