import { LinkEvents } from '../../links/LinkEvents';
import EventsBySeverity from './EventsBySeverity';
import Widget from '../../../../common/components/Widget';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { State } from '../../../../../../types';
import { getAccessToken } from '../../../../../../configuration';
import EventsModal from './EventsModal';
import OptionsDropdown, { EventsWidgetDropdown } from './OptionsDropdown';
import subscribeToVehicleEventUpdate, {
    unsubscribeToVehicleEventUpdate,
} from '../../../queries/subscribeToVehicleEventUpdates';
import { useCallback, useEffect, useState } from 'react';
import EventSettingsModal from './settings/EventSettingsModal';
import useLatestEventsAPI from '../../../queries/useLatestEventsAPI';

const EventsWidget = (): JSX.Element => {
    const intl = useIntl();
    const [lastEventId, setLastEventId] = useState<number>();

    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { isPending: loading, isError: error, events, refetch: refetchLatest } = useLatestEventsAPI(accessToken);

    const updateEvents = useCallback(
        (lastId: number) => {
            refetchLatest();
            setLastEventId(lastId);
        },
        [refetchLatest]
    );

    useEffect(() => {
        subscribeToVehicleEventUpdate(accessToken, updateEvents);
        return unsubscribeToVehicleEventUpdate;
    }, [accessToken, updateEvents]);

    return (
        <>
            <Widget
                title={intl.formatMessage({ id: 'e4c.overview.events.widget.modal.title' })}
                link={!error && <LinkEvents />}
                options={
                    <OptionsDropdown
                        customDropdown={
                            <EventsWidgetDropdown
                                accessToken={accessToken}
                                disabled={Boolean(!events?.length)}
                                lastEventId={lastEventId}
                            />
                        }
                    />
                }
            >
                <EventsBySeverity loading={loading} error={error} events={events} />
            </Widget>
            <EventsModal />
            <EventSettingsModal />
        </>
    );
};

export default EventsWidget;
